<template>
	<footer class="bdT ta-c p-30 lh-0 fsz-sm c-grey-600">
		<span><!--router-link :to="{name: 'dashboard'}" title="Sender Lead">Sender Lead</router-link--> &copy; All rights reserved. {{ thisYear }}</span>
	</footer>
</template>

<script>
	export default {
		name: 'Translate',
		data() {
			return {
				//
				thisYear: new Date().getFullYear(),
			}
		},
		computed : {
			
		},
		methods: {
			
		}
	}
</script>


<style lang="scss" scope>
	footer {
		background: white;
		box-shadow: 0px 0px 4px #e2e2e2;
	}
</style>