<template>
	<div id="builder">
		<notifications group="ToastNotify" position="bottom right"></notifications>
		<div class="page-header-wrapper">
			<page-header></page-header>
		</div>
		<builder-content></builder-content>
	</div>
</template>

<script>
	import PageHeader from './components/page-header'
	import BuilderContent	 from "./Content.vue";
	export default {
		name	: 'BuilderPageLayout',
		components: {
			PageHeader,
			BuilderContent,
		},
		
		mounted() {
			
		},
		
		data() {
			return {
				
			}
		},
		computed : {
			
		},
		methods: {
			
		},
	};
</script>

<style lang="scss">
	.page-editor {
		p {
			margin: 0px;
		}
	}
	*:focus {
		outline: none !important;
	}
</style>
