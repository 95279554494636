<template>
	<div :class="[ $store.state.isCollapsed ? 'is-collapsed' : '']">
		
		<loading></loading>
		
		<left-sidebar @onClick="NavAction"></left-sidebar>
		<div class="page-container">
			<navbar @onClick="NavAction" @logout="logout"></navbar>
			<dashboard-content></dashboard-content>
			<footer-content></footer-content>
		</div>
		<notifications group="ToastNotify" position="bottom right"></notifications>
		
		<modal v-show="$store.state.FundsModal" size="sm" hide-footer hide-header>
			<template v-slot:body>
				<div class="col-md-12 label-weight"><!--  -->
					
					<div class="row mb-4">
						<div class="col-md-6 col-6 fs-20 default-dark">
							Add funds <i class="fa fa-cog fa-spin" v-if="processing"></i>
						</div>
						<div class="col-md-6 col-6 text-right pr-0">
							<i class="btn text-secondary ti-close" @click="$store.state.FundsModal = false"></i>
						</div>
					</div>
					<div class="row">
						<div class="col-md-12 text-center">
							
							<div class="form-group btn-group btn-group-toggle">
								<template v-for="(item, id) in RangePrice">
									<label v-bind:key="`range-${id}`" v-if="CheckPrice(item.price)"
									:class="['btn btn-outline-primary py-2', $store.state.AddFunds.Amount == item.price ? 'active' : '', CheckPrice(item.price) ? '' : 'disabled']">
										<input type="radio" :name="`options-${id}`" v-model="$store.state.AddFunds.Amount" :value="item.price" 
										:disabled="!CheckPrice(item.price)"> {{ item.title }}
									</label>
								</template>
							</div>
							
							<div>
								Amount: <b><span>{{ $store.state.AddFunds.Amount }} {{ $store.state.AddFunds.Currency }}</span></b>
							</div>
						</div>
						<div class="form-group col-md-12">
							<label for="description">Description (Optional)</label>
							<textarea class="form-control" placeholder="Description" rows="8" v-model="description"></textarea>
						</div>
						
						<div class="col-md-6 mx-auto">
							<pay-pal :Amount="$store.state.AddFunds.Amount" :Currency="$store.state.AddFunds.Currency" @onAuthorize="onAuthorize"></pay-pal>
						</div>
						
						<!--div class="col-md-12 text-right">
							<button class="btn btn-primary" @click="AddFunds()" :disabled="processing">
								<i class="fa fa-cog fa-spin" v-if="processing"></i> 
								<i class="fa fa-money" v-else></i> Add funds
							</button>
						</div-->
						
					</div>
					
				</div>
			</template>
			<!--template v-slot:footer>
				<div>
					<button type="button" class="btn btn-primary">
						<i class="fa fa-cog fa-spin" v-if="Loading.Schedule"></i> 
						<i class="fa fa-calendar" v-else></i> Schedule
					</button>
				</div>
			</template-->
		</modal>
		
		<modal v-show="$store.state.PlansModal" size="max" hide-footer hide-header>
			<template v-slot:body>
				<div class="col-md-12">
					
					<div class="row mb-4">
						<div class="col-md-6 col-6 fs-20 default-dark"><i class="text-secondary ti-medall"></i> Plans</div>
						<div class="col-md-6 col-6 text-right pr-0">
							<i class="btn text-secondary ti-close" @click="$store.state.PlansModal = false"></i>
						</div>
					</div>
					
					<div class="row panel pricing-table">
						
						<div class="col-12 col-sm-12 col-md-6 col-lg-3" v-for="(plan, id) in ListPlans" v-bind:key="`plan-${id}`">
							<div class="pricing-plan">
								<span class="rounded-pill badge-plan" v-if="current_plan == plan.name">Current plan</span>
								<div class="text-center">
									<img :src="LoadImage(plan.name)" :alt="plan.name" class="pricing-img" />
								</div>
								<h2 class="pricing-header">{{ TitlePlans[plan.name] }}</h2>
								<b class="feature-title">{{ FeatureTitle[plan.name] }}</b>
								<ul class="pricing-features">
									<li class="pricing-features-item" v-for="(feature, key) in plan.features" v-bind:key="`feature-${key}`">{{ feature }}</li>
								</ul>
								<div class="row">
									<div class="col-4 my-auto"><span class="pricing-price">${{ plan.price }}</span>/Mo</div>
									<div class="col-8 text-right pl-0">
										<button class="custom-btn custom-btn-primary" @click="ChangePlan(plan)" :disabled="current_plan == plan.name">
											{{ PlanButton(plan.name) }} <i class="ti-medall"></i>
										</button>
									</div>
								</div>
							</div>
						</div>
						
					</div>
					
				</div>
			</template>
		</modal>
		
		<modal size="confirm" v-show="ModalChange" hide-header hide-footer>
			<template v-slot:body>
				<div class="text-center">
					<div>
						<img :src="LoadImage( CheckedPlan.name )" style="width: 9em;" />
					</div>
					<div class="mb-3">
						<h4>Please confirm ?</h4>
						<p class="m-0" style="font-size: 13px;">Do you want really to change your plan ?</p>
						{{ PlanButton(CheckedPlan.name) }} to <span class="h6 text-capitalize">{{ CheckedPlan.name }}</span>
					</div>
					<div>
						<button class="btn btn-secondary mR-10" @click="ModalChange = false">Cancel</button>
						<button class="btn btn-primary" @click="ChangeSubscription" :disabled="Loading.changeplan">
							<i class="fa fa-cog fa-spin" v-if="Loading.changeplan"></i> 
							<i class="ti-medall mR-2 default-size" v-else></i> <span>{{ PlanButton(CheckedPlan.name) }}</span>
						</button>
					</div>
				</div>
			</template>
		</modal>
		
	</div>
</template>

<script>
	import { Loading, Modal, PayPal }		from "@/components"
	import Navbar 			from "./components/NavbarContent.vue";
	import LeftSidebar 		from "./components/LeftSidebar.vue";
	import FooterContent 	from "./components/FooterContent.vue";
	
	import DashboardContent	from "./Content.vue"
	
	export default {
		name: 'DashboardLayout',
		components: {
			Navbar,
			LeftSidebar,
			FooterContent,
			DashboardContent,
			Loading,
			Modal,
			PayPal
		},
		created() {
			let that = this
			that.$store.state.isCollapsed = that.isIsset(that.$store.state.user.config.isCollapsed) ? that.$store.state.user.config.isCollapsed : true
			
			that.GetPlans()
			that.$store.dispatch('SidebarCounter')
		},
		mounted() {
			
		},
		data() {
			return {
				// 
				ListPlans	: [],
				TitlePlans	: { 'starter': 'Starter', 'basic': 'Basic', 'business': 'Business', 'enterprise': 'Rapid Growth' },
				FeatureTitle: {
					'starter'	: 'Includes:',
					'basic'		: 'Everything in STARTER and more:',
					'business'	: 'Everything in BASIC and more:',
					'enterprise': 'Everything in BUSINESS and more:'
				},
				current_plan: '',
				user_email	: '',
				user_id		: '',
				
				
				processing 	: false,
				/* RangePrice 	: [
					{ price: '25', 	title: '25 USD'  },
					{ price: '30', 	title: '30 USD'  },
					{ price: '50', 	title: '50 USD'  },
					{ price: '75', 	title: '75 USD'  },
					{ price: '100', title: '100 USD' },
				], */
				
				description	: '',
				
				CheckedPlan	: { name: 'starter' },
				ModalChange : false,
				Loading		: { changeplan: false }
			}
		},
		computed : {
			RangePrice () {
				
				let that		= this, Amounts = [ '25', '30', '50', '75', '100' ], RangePrice = []
				let Currency	= that.$store.state.AddFunds.Currency
				let NewAmount	= that.$store.state.AddFunds.NewAmount
				let Amount		= parseFloat( NewAmount )
				
				if( !Amounts.includes(NewAmount) && NewAmount != '' && Amount > 0 && Amount != 'NaN' ) {
					Amounts.push(NewAmount)
				}
				Amounts.sort((a,b) => a-b)
				Amounts.forEach( function (amount) {
					RangePrice.push( { price: amount, title: amount + ' ' + Currency } )
				} )
				
				return RangePrice
				// that.$store.state.AddFunds.Amount
				// that.$store.state.AddFunds.Currency
				// let 
				
			}
		},
		methods: {
			ChangeSubscription() {
				
				let that = this
				that.Loading.changeplan = true
				let url  	= '/plan/change'
				let method  = 'POST'
				let request = { url: url, method: method, data: that.CheckedPlan }
				
				that.$store.dispatch('requests', request).then((response) => {
					that.Loading.changeplan = false
					if( response.data.status == 'success' ) {
						// that.ModalChange = false
						that.current_plan = that.CheckedPlan.name
						that.ToastNotify('success', 'Success', response.data.message)
					} else { // success
						that.ToastNotify( 'warning', 'Opps!', response.data.message )
					}
				}).catch(({response}) => {
					that.Loading.changeplan = false
					that.ErrorHandler(response)
				} )
				
			},
			ChangePlan(plan) {
				let that = this
				
				Object.keys(plan).forEach( function(field) {
					that.CheckedPlan[field] = plan[field]
				} )
				
				if( that.current_plan != plan.name ) {
					
					if( ['starter', 'basic', 'business', 'enterprise'].includes(that.current_plan) ) {
						that.ModalChange = true
					} else {
						that.user_email = that.user_email != '' ? that.user_email : that.$store.state.user.email
						let PassThrough = {user_id: that.user_id, email: that.user_email}
						
						window.Paddle.Checkout.open({
							product: plan.plan_id, email: that.user_email, passthrough: JSON.stringify(PassThrough), allowQuantity:false, disableLogout:true,
							successCallback: that.successCallback, closeCallback: that.closeCallback
						})
						
					}
					
					// passthrough: "{\"user_id\": 98765, \"affiliation\": \"Acme Corp\"}"
				}
			},
			successCallback() {
				let that = this
				that.current_plan = that.CheckedPlan.name
				that.ToastNotify('success', 'Success', 'You have successfully subscribed to ' + that.current_plan + ' plan')
				setTimeout( function() {
					location.reload()
				}, 3000 )
			},
			closeCallback() {
				
			},
			
			GetPlans() {
				let that = this
				
				let url  	= '/plans'
				let method  = 'GET'
				let data 	= {}
				let request = { url: url, method: method, data: data }
				
				that.$store.dispatch('requests', request).then((response) => {
					let feedback 	= response.data
					const user 		= feedback.user
					
					that.current_plan	= feedback.plan
					that.user_email		= user.email
					that.user_id		= feedback.id
					feedback.plans.forEach( function(plan) {
						that.ListPlans.push(plan)
					} )
					
					localStorage.setItem('sl-user', JSON.stringify(user) )
					localStorage.setItem('sl-token', user.api_token)
					that.$http.defaults.headers.common['Authorization'] = 'Bearer ' + user.api_token
					that.$store.state.user = user
					
					that.$store.state.isCollapsed = that.isIsset(user.config.isCollapsed) ? user.config.isCollapsed : true
				}).catch(({response}) => {
					that.ErrorHandler(response)
				} )
			},
			LoadImage(image) {
				return require('@/assets/HomePage/img/Pricing/' + image + '.png')
			},
			PlanButton(plan) {
				let that = this
				if( that.current_plan == plan ) {
					return 'Current'
				} else if( that.current_plan == 'starter' ) {
					return 'Upgrade'
				} else if( that.current_plan == 'enterprise' ) {
					return 'Downgrade'
				} else if( that.current_plan == 'basic' ) {
					if( plan == 'starter' ) {
						return 'Downgrade'
					} else {
						return 'Upgrade'
					}
				} else if( that.current_plan == 'business' ) {
					if( plan == 'enterprise' ) {
						return 'Upgrade'
					} else {
						return 'Downgrade'
					}
				} else {
					return 'Get started'
				}
			},
			
			onAuthorize(response) {
				// AddFunds
				let that = this
				that.processing = true
				
				let url  	= '/funds/add'
				let method  = 'POST'
				let data 	= { funds: that.$store.state.AddFunds.Amount, currency: that.$store.state.AddFunds.Currency, description: that.description, response: response }
				let request = { url: url, method: method, data: data }
				
				that.$store.dispatch('requests', request).then((response) => {
					let feedback = response.data
					if(feedback.status == 'success' ) {
						that.ToastNotify('success', 'Success', 'You have successfully add funds to your account')
						that.$store.state.FundsModal = false
						that.description  = ''
						that.$store.state.AddFunds.Amount = '25'
						// that.$store.state.AddFunds.NewAmount = '25'
						that.$store.state.user['funds'] = feedback.funds
						let user = JSON.stringify(that.$store.state.user)
						localStorage.setItem('sl-user', user )
					}
					that.processing = false
				}).catch(({response}) => {
					// let errors = response.data.errors
					that.ErrorHandler(response)
					that.processing = false
				})
			},
			NavAction(action) {
				let that = this
				// FundsModal
				if( action == 'sidebar' ) {
					that.$store.state.isCollapsed = !that.$store.state.isCollapsed
					that.ChangeConfig( { action: 'isCollapsed', content: that.$store.state.isCollapsed } )
				} else if( action == 'FundsModal' ) {
					that.$store.state.FundsModal = !that.$store.state.FundsModal
				}
			},
			
			logout() {
				let that = this
				that.$store.state.loading = true
				that.$store.dispatch('logout').then(() => {
					that.$store.state.loading = false
					that.$router.push('/login')
				})
			},
			
			CheckPrice(price) {
				return parseFloat(this.$store.state.AddFunds.NewAmount) <= parseFloat(price)
			}
			
		},
	};
</script>

<style lang="scss">
	.fade-enter-active, .fade-leave-active {
		transition: opacity .5s;
	}
	.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
		opacity: 0;
	}
	
	.badge-plan {
		color: #28a745;
		background-color: #ccffd8;
		padding: 8px;
		font-size: 12px;
		font-weight: 500;
		position: absolute;
		right: 30px;
		top: 14px;
	}
</style>
