<template>
	<!-- ### $App Screen Content ### -->
	<main class="main-content bgc-grey-100">
		<div id="mainContent">
			<transition name="fade" mode="out-in">
				<router-view></router-view>
			</transition>
		</div>
	</main>
	
</template>
<script>
	export default {
		name: 'LayoutDashboardContent',
	};
</script>
<style lang="scss">
	
</style>
 