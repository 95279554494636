<template>
	<div class="page-home">
		<div class="home-side-bar">
			
			<el-menu default-active="/builder/works" router>
				<el-menu-item index="/builder/works">
					<i class="el-icon-mobile"></i> <span slot="title">My works</span>
				</el-menu-item>
				<el-menu-item index="/builder/templates">
					<i class="el-icon-document"></i> <span slot="title">My templates</span>
				</el-menu-item>
				<!--el-menu-item index="/builder/template-list">
					<i class="el-icon-s-shop"></i> <span slot="title">Templates store</span>
				</el-menu-item-->
			</el-menu>
		</div>
		<div class="clearfix my-page-list">
			<transition name="fade" mode="out-in">
				<router-view class="sub-page" />
			</transition>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'LayoutBuilderContent',
	};
</script>

<style lang="scss" scoped>
	.page-home {
		display: flex;
		width: 100%;
		height: 100vh;
		background: rgb(240, 243, 244);
		.home-side-bar {
			width: 210px;
			background-color: #fff;
			border-right: 1px solid #e6ebed;
			z-index: 2;
		}
		.my-page-list {
			flex: 1;
			height: 100%;
			.sub-page {
				height: 100%;
			}
		}
	}
	
	.el-menu {
		border: 0px;
	}
	.el-dropdown-link{
		color: white;
	}
</style>

<style lang="scss">
	.my-page-list {
		.page-search-wrapper {
			.el-tabs__header {
				margin: 0;
			}
		}
	}
	
	.el-loading-mask {
		position: fixed !important;
	}
</style>
