<template>
    <div class="header navbar">
        <div class="header-container">
            <ul class="nav-left">
                <li>
                    <a id='sidebar-toggle' class="sidebar-toggle" href="javascript:void(0);" @click="$emit('onClick', 'sidebar')">
						<i class="ti-menu"></i>
					</a>
                </li>
				
				<!--li v-if="CurrentPlan == 'trial' && DiffDays > 0 && DiffDays <= 7">
					<div class="col-12 mY-15 text-dark">
						You have <b>{{ DiffDays }}</b> day<span v-if="DiffDays > 1">s</span> in your free trial plan.
						<button class="btn btn-link pX-5" @click="$store.state.PlansModal = true">Upgrade now</button>
					</div>
				</li-->
				
				<!--li class="border-right">
                    <a href="javascript:void(0);">
						<i class="fa fa-commenting-o"></i> Chat / ChatBot
					</a>
                </li-->
                <!--li :class="['search-box', searchBox ? 'active' : '']">  // search Zone do not move it ---------------------------------------------------------
                    <a class="search-toggle no-pdd-right" href="javascript:void(0);" @click="collapseSearch">
                        <i class="search-icon ti-search pdd-right-10"></i>
                        <i class="search-icon-close ti-close pdd-right-10"></i>
                    </a>
                </li>
                <li :class="['search-input', searchBox ? 'active' : '']">
                    <input class="form-control" type="text" placeholder="Search..." ref="searchBox">
                </li-->
            </ul>
            <ul class="nav-right" ref="closepanel">
                <li style="margin-top:12px" class="d-none">
					<translate></translate>
				</li>
				<li>
					<a href="javascript:void(0);" :title="`Current Balance: ${'$ ' + Funds.amount} Click to add funds`" @click="$emit('onClick', 'FundsModal')">
						<b>$ {{ Funds.amount }}</b>
						<!--svg id="sl-rocket" viewBox="0 0 1024 1024">
							<title>rocket</title>
							<path class="path1" d="M691.2 460.8c-70.579 0-128-57.421-128-128s57.421-128 128-128 128 57.421 128 128-57.421 128-128 128zM691.2 256c-42.347 0-76.8 34.453-76.8 76.8s34.453 76.8 76.8 76.8 76.8-34.453 76.8-76.8-34.453-76.8-76.8-76.8z"></path>
							<path class="path2" d="M25.603 1024c-6.675 0-13.219-2.613-18.106-7.499-7.034-7.034-9.355-17.502-5.957-26.85 78.781-216.648 161.613-326.499 246.195-326.499 27.883 0 53.979 11.96 77.566 35.546 37.283 37.283 38.611 74.394 33.162 98.96-17.125 77.187-126.171 152.822-324.115 224.802-2.853 1.038-5.813 1.541-8.746 1.541zM247.736 714.354c-25.354 0-55.19 22.214-86.282 64.237-30.578 41.33-61.274 100.205-91.525 175.477 68.352-27.478 123.302-55.379 163.806-83.205 54.648-37.542 70.808-66.562 74.742-84.294 3.944-17.779-2.395-34.682-19.382-51.667-13.826-13.826-27.354-20.547-41.36-20.547z"></path>
							<path class="path3" d="M998.4 0c-132.848 0-251.256 22.534-351.939 66.981-82.997 36.638-154.075 88.075-211.258 152.882-10.674 12.098-20.552 24.334-29.691 36.586-44.142 2.942-89.275 20.47-134.362 52.221-38.13 26.851-76.459 64.014-113.923 110.458-62.965 78.054-101.706 154.987-103.325 158.226-5.605 11.211-2.25 24.814 7.904 32.166 4.501 3.258 9.758 4.856 14.992 4.856 6.573 0 13.109-2.52 18.064-7.434 0.243-0.24 24.714-24.299 66.469-47.926 34.41-19.474 87.461-42.336 151.613-46.384 16.219 41.541 62.662 91.181 84.954 113.47 22.291 22.291 71.931 68.734 113.472 84.955-4.046 64.152-26.91 117.202-46.382 151.611-23.629 41.757-47.686 66.227-47.89 66.432-8.878 8.878-10.006 22.885-2.666 33.070 4.952 6.87 12.77 10.634 20.782 10.634 3.867 0 7.779-0.877 11.434-2.704 3.237-1.619 80.17-40.36 158.226-103.325 46.443-37.464 83.606-75.794 110.458-113.922 31.75-45.088 49.278-90.221 52.221-134.363 12.251-9.139 24.49-19.019 36.586-29.693 64.806-57.181 116.243-128.259 152.883-211.258 44.443-100.682 66.979-219.091 66.979-351.939v-25.6h-25.6zM159.102 502.187c48.797-70.8 123.384-158.595 207.446-186.232-33.222 58.203-50.422 111.691-56.611 145.555-59.323 3.626-110.467 20.89-150.835 40.677zM521.87 864.781c19.762-40.35 36.995-91.453 40.619-150.718 33.859-6.187 87.336-23.384 145.528-56.597-27.658 83.92-115.381 158.49-186.147 207.315zM770.262 550.405c-106.48 93.952-216.794 115.195-232.662 115.195-0.102 0-10.581-0.23-38.867-20.136-19.728-13.883-42.682-33.618-64.63-55.566-21.95-21.95-41.683-44.902-55.566-64.632-19.906-28.285-20.136-38.763-20.136-38.866 0-15.869 21.243-126.182 115.197-232.662 112.416-127.406 284.533-197.059 498.894-202.227-5.17 214.358-74.822 386.477-202.229 498.894z"></path>
						</svg-->
					</a>
				</li>
				<!--li :class="['notifications', 'dropdown', collapse.alerts ? 'show' : '']">
                    <span class="counter bgc-red">3</span>
                    <a href="javascript:void(0);" class="dropdown-toggle no-after" @click="eventsCollapse('alerts')">
                        <i class="ti-bell"></i>
                    </a>
					
                    <ul :class="['dropdown-menu', collapse.alerts ? 'show' : '']">
                        <li class="pX-20 pY-15 bdB">
                            <i class="ti-bell pR-10"></i>
                            <span class="fsz-sm fw-600 c-grey-900">Notifications</span>
                        </li>
                        <li>
                            <ul class="ovY-a pos-r scrollable lis-n p-0 m-0 fsz-sm">
                                <li>
                                    <a href="javascript:void(0);" class='peers fxw-nw td-n p-20 bdB c-grey-800 cH-blue bgcH-grey-100'>
                                        <div class="peer mR-15">
                                            <img class="w-3r bdrs-50p" src="https://randomuser.me/api/portraits/men/1.jpg" alt="">
                                        </div>
                                        <div class="peer peer-greed">
                                            <span>
											<span class="fw-500">John Doe</span>
                                            <span class="c-grey-600">liked your <span class="text-dark">post</span></span>
                                            </span>
                                            <p class="m-0"><small class="fsz-xs">5 mins ago</small></p>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);" class='peers fxw-nw td-n p-20 bdB c-grey-800 cH-blue bgcH-grey-100'>
                                        <div class="peer mR-15">
                                            <img class="w-3r bdrs-50p" src="https://randomuser.me/api/portraits/men/2.jpg" alt="">
                                        </div>
                                        <div class="peer peer-greed">
                                            <span>
											<span class="fw-500">Moo Doe</span>
                                            <span class="c-grey-600">liked your <span class="text-dark">cover image</span>
                                            </span>
                                            </span>
                                            <p class="m-0">
                                                <small class="fsz-xs">7 mins ago</small>
                                            </p>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);" class='peers fxw-nw td-n p-20 bdB c-grey-800 cH-blue bgcH-grey-100'>
                                        <div class="peer mR-15">
                                            <img class="w-3r bdrs-50p" src="https://randomuser.me/api/portraits/men/3.jpg" alt="">
                                        </div>
                                        <div class="peer peer-greed">
                                            <span>
												<span class="fw-500">Lee Doe</span>
												<span class="c-grey-600">commented on your <span class="text-dark">video</span></span>
                                            </span>
                                            <p class="m-0">
                                                <small class="fsz-xs">10 mins ago</small>
                                            </p>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li class="pX-20 pY-15 ta-c bdT">
                            <span>
								<a href="javascript:void(0);" class="c-grey-600 cH-blue fsz-sm td-n">View All Notifications <i class="ti-angle-right fsz-xs mL-10"></i></a>
							</span>
                        </li>
                    </ul>
                </li-->
                <!--li :class="['notifications', 'dropdown', collapse.notifs ? 'show' : '']">
                    <span class="counter bgc-blue">3</span>
                    <a href="javascript:void(0);" class="dropdown-toggle no-after" @click="eventsCollapse('notifs')">
                        <i class="ti-email"></i>
                    </a>

                    <ul :class="['dropdown-menu', collapse.notifs ? 'show' : '']">
                        <li class="pX-20 pY-15 bdB">
                            <i class="ti-email pR-10"></i>
                            <span class="fsz-sm fw-600 c-grey-900">Emails</span>
                        </li>
                        <li>
                            <ul class="ovY-a pos-r scrollable lis-n p-0 m-0 fsz-sm">
                                <li>
                                    <a href="javascript:void(0);" class='peers fxw-nw td-n p-20 bdB c-grey-800 cH-blue bgcH-grey-100'>
                                        <div class="peer mR-15">
                                            <img class="w-3r bdrs-50p" src="https://randomuser.me/api/portraits/men/1.jpg" alt="">
                                        </div>
                                        <div class="peer peer-greed">
                                            <div>
                                                <div class="peers jc-sb fxw-nw mB-5">
                                                    <div class="peer">
                                                        <p class="fw-500 mB-0">John Doe</p>
                                                    </div>
                                                    <div class="peer">
                                                        <small class="fsz-xs">5 mins ago</small>
                                                    </div>
                                                </div>
                                                <span class="c-grey-600 fsz-sm">
													Want to create your own customized data generator for your app...
												</span>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);" class='peers fxw-nw td-n p-20 bdB c-grey-800 cH-blue bgcH-grey-100'>
                                        <div class="peer mR-15">
                                            <img class="w-3r bdrs-50p" src="https://randomuser.me/api/portraits/men/2.jpg" alt="">
                                        </div>
                                        <div class="peer peer-greed">
                                            <div>
                                                <div class="peers jc-sb fxw-nw mB-5">
                                                    <div class="peer">
                                                        <p class="fw-500 mB-0">Moo Doe</p>
                                                    </div>
                                                    <div class="peer">
                                                        <small class="fsz-xs">15 mins ago</small>
                                                    </div>
                                                </div>
                                                <span class="c-grey-600 fsz-sm">
												Want to create your own customized data generator for your app...
												</span>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);" class='peers fxw-nw td-n p-20 bdB c-grey-800 cH-blue bgcH-grey-100'>
                                        <div class="peer mR-15">
                                            <img class="w-3r bdrs-50p" src="https://randomuser.me/api/portraits/men/3.jpg" alt="">
                                        </div>
                                        <div class="peer peer-greed">
                                            <div>
                                                <div class="peers jc-sb fxw-nw mB-5">
                                                    <div class="peer">
                                                        <p class="fw-500 mB-0">Lee Doe</p>
                                                    </div>
                                                    <div class="peer">
                                                        <small class="fsz-xs">25 mins ago</small>
                                                    </div>
                                                </div>
                                                <span class="c-grey-600 fsz-sm">
												Want to create your own customized data generator for your app...
												</span>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li class="pX-20 pY-15 ta-c bdT">
                            <span>
								<a href="javascript:void(0);" class="c-grey-600 cH-blue fsz-sm td-n">View All Email <i class="fs-xs ti-angle-right mL-10"></i></a>
							</span>
                        </li>
                    </ul>
                </li-->
                <li :class="['dropdown', collapse.profil ? 'show' : '']">
                    <a href="javascript:void(0);" class="dropdown-toggle no-after peers fxw-nw ai-c lh-1" @click="eventsCollapse('profil')">
						<div class="peer mR-10">
							<avatar v-if="userAvatar == ''" :fullname="fullName" :size="38"></avatar>
							<img v-else :src="userAvatar" class="w-2r bdrs-50p" />
						</div>
						<div class="peer">
							<span class="fsz-sm c-grey-900">{{ fullName | capitalize | maxLength }} <i :class="[collapse.profil ? 'ti-angle-up' : 'ti-angle-down']" style="font-size: 10px;"></i></span>
						</div>
                    </a>
                    
					<ul :class="['dropdown-menu fsz-sm', collapse.profil ? 'show' : '']">
                        <li @click="collapse.profil = false">
							<router-link :to="{name: 'profile'}" class="d-b td-n pY-5 bgcH-grey-100 c-grey-700">
								<i class="ti-user mR-10"></i>
                                <span>My Profile</span>
							</router-link>
                        </li>
						<li @click="collapse.profil = false">
							<router-link :to="{name: 'billing'}" class="d-b td-n pY-5 bgcH-grey-100 c-grey-700">
								<i class="fa fa-money mR-10"></i>
								<span>Invoices & Funds</span>
							</router-link>
						</li>
                        <!--li @click="collapse.profil = false">
                            <router-link :to="{name: 'teams'}" class="d-b td-n pY-5 bgcH-grey-100 c-grey-700">
								<i class="fa fa-handshake-o mR-10"></i>
								<span>Teams - Members</span>
                            </router-link>
                        </li-->
                        <li @click="collapse.profil = false">
							<router-link :to="{name: 'settings'}" class="d-b td-n pY-5 bgcH-grey-100 c-grey-700">
								<i class="ti-settings mR-10"></i>
								<span>My Settings</span>
							</router-link>
                        </li>
                        <li @click="collapse.profil = false">
							<router-link :to="{name: 'help'}" class="d-b td-n pY-5 bgcH-grey-100 c-grey-700">
								<i class="ti-comments mR-10"></i>
								<span>Support / Help</span>
							</router-link>
                        </li>
                        <!--li>
                            <a href="javascript:void(0);" class="d-b td-n pY-5 bgcH-grey-100 c-grey-700">
                                <i class="ti-email mR-10"></i>
                                <span>Messages</span>
                            </a>
                        </li-->
                        <li role="separator" class="divider"></li>
                        <li>
                            <a href="javascript:void(0);" @click.prevent="ShowPlans" class="d-b td-n pY-5 bgcH-grey-100 c-grey-700">
                                <i class="ti-medall mR-10"></i>
                                <span>Plans & Subscribe</span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" @click.prevent="$emit('logout')" class="d-b td-n pY-5 bgcH-grey-100 c-grey-700">
                                <i class="ti-power-off mR-10"></i>
                                <span>Logout</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
		
    </div>
</template>
<script>
	import { Avatar, Translate } from '@/components'
	export default {
		name: 'NavBar',
        data() {
			return {
				
				collapse	: {
					profil	: false,
					notifs	: false,
					alerts	: false,
				},
				searchBox 	: false,
				
				DiffDays	: 0
				
			}
		},
		components: {
			Translate,
			Avatar
		},
		methods	: {
			
			ShowPlans () {
				let that = this
				that.collapse.profil = false
				that.$store.state.PlansModal = true
			},
			
			collapseSearch () {
				this.searchBox = !this.searchBox
			},
			
			eventsCollapse (element) {
				let that = this
				document.addEventListener('click', this.documentClick);
				that.initCollapse(element)
				that.collapse[element] = !that.collapse[element]
			},
			
			initCollapse(element = '') {
				let that = this
				Object.keys( that.collapse ).forEach( function(el) {
					if( el != element ) {
						that.collapse[el] = false
					}
				} )
			},
			
			documentClick(e) {
				let that = this, el = this.$refs.closepanel,
					target = e.target;
				if( typeof el != 'undefined' && el != null && el != '' ) {
					if(el !== target && !el.contains(target)) {
						that.initCollapse()
						document.removeEventListener('click', that.documentClick);
					}
				}
			},
			CheckPlan() {
				let that = this
				let created_at 	 = this.$store.state.user.created_at
				var date1 = new Date(created_at)
				var date2 = new Date(); 
				
				var Difference_In_Time = date2.getTime() - date1.getTime()
				
				var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24)
					Difference_In_Days = parseInt(Difference_In_Days)
				
				setTimeout( function() {
					that.DiffDays 		= 7 - Difference_In_Days
				}, 60000 )
				
				
			}
		},
		mounted() {
			
		},
		computed: {
			
			CurrentPlan() {
				let that = this
				let CurrentPlan = this.$store.state.user.plan
				that.CheckPlan()
				return CurrentPlan
			},
			
			fullName() {
				let that = this
				let user = that.$store.state.user
				return user.fullname
			},
			userAvatar() {
				let that = this
				let user = that.$store.state.user
				return user.avatar != null ? user.avatar : ''
			},
			Funds() {
				let that = this
				let user = that.$store.state.user
				let amount   = ''
				let currency = ''
				if( that.isIsset(user) ) {
					amount   = user.funds.amount
					currency = user.funds.currency
				}
				return { amount: amount, currency: currency }
			}
		},
		filters: {
			maxLength: function (str) {
				let count = 20
				if( str != null && str != '' ) {
					return str.trim().length > count ? str.trim().substring(0, count) + "..." : str.trim()
				} else {
					return ''
				}
			}
		},
	}
</script>
<style lang="scss" scope>
	.header {
		box-shadow: 0px 0px 2px #dadada;
		.header-container {
			.nav-right {
				.dropdown-menu {
					li {
						a {
							i {
								vertical-align: middle;
							}
						}
					}
				}
				>li.dropdown {
					padding: 0px 2px;
					border-left: 1px solid #eee;
				}
				
				@media screen and (max-width: 640px) {
					>li a:hover, >li a:focus {
						background: #fff;
						color: #1e282c !important;
					}
				}
				
			}
			
			.nav-left {
				margin-left: 0px !important;
				>li:first-child {
					border-right: 1px solid #eee;
				}
				.search-input {
					line-height: 65px;
					min-height: 65px;
				}
			}
		}
	}
</style>