<template>
	<div id="content" data-view>
		<navbar-content></Navbar-content>
		
		<home-content></home-content>
		
		<footer-content></footer-content>
	</div>
</template>

<script>
	import FooterContent from "./components/FooterContent.vue";
	import NavbarContent from "./components/NavbarContent.vue";
	import HomeContent	 from "./Content.vue";
	export default {
		name	: 'HomePageLayout',
		components: {
			FooterContent,
			NavbarContent,
			HomeContent
		},
		
		mounted() {
			/* window.addEventListener('load', function load() {
				const loader = document.getElementById('loader');
				setTimeout(function() {
				  loader.classList.add('fadeOut');
				}, 300);
			}); */
		},
		
		data() {
			return {
				
			}
		},
		computed : {
			
		},
		methods: {
			
		},
	};
</script>

<style lang="scss">
	
	@import '../../assets/HomePage/font/flaticon/flaticon.css';
	@import '../../assets/HomePage/css/Ellipse-animation.css';
	@import '../../assets/HomePage/css/icons-style.css';
	@import '../../assets/css/custom-button.css';
	@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css';
	@import '../../assets/css/animate.min.css';
	
	#content {
		width: 100%;
	}
	
</style>
