import Vue from 'vue'

import store from './config/store'
import router from './config/router'
import i18n from '@/config/i18n';
import mixin from '@/config/mixin';

// -----------------------------------------------------------------------------
import * as mUtils from '@/assets/Builder/js/mUtils'

import config from '@/config/Builder'
import filters from './config/filters'

import Element from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
Vue.use(Element, { locale });

import '@/assets/Builder/styles/element-variables.scss'
import '@/assets/Builder/styles/index.scss'

Vue.prototype.$mUtils = mUtils
Vue.prototype.$config = config

Object.keys(filters).forEach(key => {
	Vue.filter(key, filters[key])
})

// -----------------------------------------------------------------------------

require('./assets/sass/app.scss')
// require('./assets/css/animate.min.css')

Vue.config.productionTip = false

import App from './views/App.vue'

import FlagIcon from 'vue-flag-icon';
Vue.use(FlagIcon);

// --------------------
// import DateRangePicker from './plugins/vue-mj-daterangepicker/src/components'
// import 'vue-mj-daterangepicker/dist/vue-mj-daterangepicker.css'
// Vue.use(DateRangePicker)
// --------------------

import Notifications from './components/plugin/notification'
Vue.use(Notifications)

// --------------------
Vue.mixin(mixin)

new Vue({
	render: h => h(App),
	router,
	store,
	i18n,
	// mixin // md
}).$mount('#app')
