<template>
	<nav class="navbar navbar-expand-sm navbar-light bg-white">
		<div class="container">
			<router-link class="navbar-brand d-flex" :to="{ name : 'home' }">
				<img alt="Sender Lead" src="@/assets/HomePage/img/Logo/Icon.png" style=" width: 52px; " /> <span class="h3 m-auto text-primary">SenderLead</span>
			</router-link>
			
			<!--button type="button" class="navbar-toggler">
				<span class="navbar-toggler-icon"></span>
			</button-->
			
			<button type="button" id="toggle" @click="ToggleMenu = !ToggleMenu" :class="[ToggleMenu ? 'on' : '', 'navbar-toggler']">
				<span class="navbar-toggler-icon"></span>
			</button>
			<div id="navbarNav">
				<ul>
					
					<!--UNLOGGED-->
					<template v-if="!isLoggedIn">
						<li v-for="(route, key) in routes.unlogged" v-bind:key="`route-${key}`">
							<router-link class="nav-link pt-0 pb-0" :to="{ name : route.path }">
								{{ route.title }}
							</router-link>
						</li>
					</template>
					
					<!--LOGGED USER-->
					<template v-if="isLoggedIn">
						<li v-for="(route, key) in routes.user" v-bind:key="`route-${key}`">
							<router-link class="nav-link" :to="{ name : route.path }">
								{{ route.title }}
							</router-link>
						</li>
					</template>
					
					<!--LOGOUT-->
					<li v-if="isLoggedIn">
						<a href="javascript:void(0);" class="nav-link" @click.prevent="logout()">Logout</a>
					</li>
					
				</ul>
			</div>
			
			
			<div class="collapse navbar-collapse mB-8">
				
				<ul class="navbar-nav ml-auto"><!-- ml-lg-auto -->
					
					<!--UNLOGGED-->
					<template v-if="!isLoggedIn">
						<li class="nav-item mL-10" v-for="(route, key) in routes.unlogged" v-bind:key="`route-${key}`">
							<router-link class="nav-link pt-0 pb-0" :to="{ name : route.path }">
								{{ route.title }}
							</router-link>
						</li>
					</template>
					
					<!--LOGGED USER-->
					<template v-if="isLoggedIn">
						<li class="nav-item mL-10" v-for="(route, key) in routes.user" v-bind:key="`route-${key}`">
							<router-link class="nav-link" :to="{ name : route.path }">
								{{ route.title }}
							</router-link>
						</li>
					</template>
					
					<!--LOGOUT-->
					<li class="nav-item" v-if="isLoggedIn">
						<a href="javascript:void(0);" class="nav-link" @click.prevent="logout()">Logout</a>
					</li>
					<!--div class="ml-lg-4">
						<div class="color-mode d-lg-flex justify-content-center align-items-center">
							<i class="color-mode-icon"></i> Color mode
						</div>
					</div-->
					
				</ul>
			</div>
		</div>
	</nav>
</template>
<script>
	export default {
		name: 'NavbarContent',
		data() {
			return {
				routes	: {
					// UNLOGGED
					unlogged: [
						
						{ title: 'Home', 	 path: 'home' },
						// { title: 'Pricing',  path: 'pricing' },
						{ title: 'Signin',   path: 'login' },
						{ title: 'Join now', path: 'register' }
						
					],
					// LOGGED USER
					user: [
						{ title: 'Dashboard', path: 'dashboard' }
					]
				},
				ToggleMenu: false
			}
		},
		
		methods: {
			logout() {
				this.$store.dispatch('logout').then(() => {
					this.$router.push('/login')
				})
			}
		},
		
		computed : {
			isLoggedIn : function() {
				return this.$store.getters.isLoggedIn
			},
		}
	};
</script>
<style lang="scss">
	
	/* on activation */
	#toggle.on span {
		background-color: transparent;
	}
	#toggle.on span:before {
		transform: rotate(45deg) translate(5px, 5px);
	}
	#toggle.on span:after {
		transform: rotate(-45deg) translate(7px, -8px);
	}
	#toggle.on + #navbarNav {
		opacity: 1;
		visibility: visible;
	}
	/* menu appearance*/
	#navbarNav {
		position: absolute;
		right: 12px;
		top: 70px;
		width: 200px;
		padding: 10px;
		margin: auto;
		border-radius: 4px;
		background: white;
		box-shadow: 0 1px 8px rgba(0,0,0,0.05);
		/* just for this demo */
		opacity: 0;
		visibility: hidden;
		transition: opacity .4s;
		border: 1px solid rgba(0, 0, 0, 0.1);
		color: rgba(0, 0, 0, 0.5);
		
		ul, li, li a {
			list-style: none;
			display: block;
			margin: 0;
			padding: 0;
			line-height: 26px;
		}
		li a {
			padding: 5px;
			color: #888;
			text-decoration: none;
			transition: all .2s;
		}
		li a:hover, li a:focus {
			background: #2196f3;
			color: #fff;
		}
		
		&:after, &:before {
			bottom: 100%;
			left: 82%;
			border: solid transparent;
			content: "";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
		}
		&:after {
			border-color: rgba(136, 183, 213, 0);
			border-bottom-color: #ffffff;
			border-width: 12px;
			margin-left: 0px;
		}
		&:before {
			border-color: rgba(194, 225, 245, 0);
			border-bottom-color:  #ccc;
			border-width: 12px;
			margin: 0px 0px 1px 0px;
		}
		
	}
	
</style>
