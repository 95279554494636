<template>
	<transition name="fade" mode="out-in">
		<router-view></router-view>
	</transition>
</template>
<script>
	export default {
		name: 'LayoutHomeContent',
	};
</script>
<style lang="scss">
	
</style>
