<template>
	<div :class="['sidebar', $store.state.isCollapsed ? '' : 'is-toggle']">
		<onBoard></onBoard>
		<div class="sidebar-inner">
			<!-- ### $Sidebar Header ### -->
			<div class="sidebar-logo">
                <div class="peers ai-c fxw-nw">
                    <div class="peer peer-greed">
                        <router-link :to="{name: 'dashboard'}" class="sidebar-link td-n">
                            <!--a class="sidebar-link td-n" href="#"-->
                            <div class="peers ai-c fxw-nw">
                                <div class="peer">
                                    <div class="logo d-flex">
										<img src="/img/Logo.png" class="d-block m-auto" alt="" style="width: 35px;" />
										<!--img src="/img/Logo.svg" class="d-block m-auto" alt="" style="width: 35px;" /-->
									</div>
                                </div>
                                <div class="peer peer-greed">
									<h5 class="lh-1 mB-0 logo-text">Sender Lead</h5>
								</div>
                            </div>
                            <!--/a-->
                        </router-link>
                    </div>
                    <div class="peer">
                        <div class="mobile-toggle sidebar-toggle">
                            <a href="javascript:void(0);" class="td-n" @click="$emit('onClick', 'sidebar')">
                                <i class="ti-arrow-circle-left"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
			
            <!-- ### $Sidebar Menu ### -->
            <ul class="sidebar-menu scrollable pos-r ps dark-sidebar">
                
				<li class="nav-item mT-20 text-center">
					<!--a class="sidebar-link" href="">
						<span class="icon-holder"><i class="c-brown-500 ti-email"></i></span>
						<span class="title">Email (Just admin)</span>
                    </a-->
					<a class="sidebar-link" href="javascript:void(0);">
						<span class="title">
							<drop-options v-model="$store.state.TeamSelected" :options="$store.state.MyTeams" label="Select team" :icon="IconTeams" @change="ChangeTeam" maxLength="17"></drop-options>
						</span>
					</a>
                </li>
				
				<li v-for="(item, key) in menuItems" v-bind:key="`item-${key}`" 
				:class="['nav-item', key == 0 ? 'mT-20' : '', item.name == '' ? 'dropdown' : '', (item.selected && item.name == '') ? 'active' : '']">
                    <router-link :to="{name: item.name}" :class="['sidebar-link', PageSelected == item.name ? 'active' : '']" v-if="item.name != ''">
						<div @click="$store.state.selectedPage = item.name">
							<span class="icon-holder"><i :class="[item.icon]"></i></span>
							<span class="title" v-html="item.title"></span>
						</div>
                    </router-link>
					
					<template v-else>
						<a class="dropdown-toggle" href="javascript:void(0);" @click="item.selected = !item.selected">
							<span class="icon-holder"><i :class="[item.icon]"></i></span>
							<span class="title" v-html="item.title"></span>
							<span class="arrow"><i class="ti-angle-right"></i></span>
						</a>
						<ul class="dropdown-ul">
							<li v-for="(child, id) in item.children" v-bind:key="`child-${id}`" @click="$store.state.selectedPage = child.name">
								<router-link :to="{name: child.name}" :class="['sidebar-link', PageSelected == child.name ? 'active' : '']" class="d-flex justify-content-between">
									<span>{{ child.title }}</span>
									<span class="badge badge-primary rounded-pill my-auto mR-10" v-if="$store.state.CounterData[child.name] != null">{{ $store.state.CounterData[child.name] }}</span>
								</router-link>
							</li>
						</ul>
					</template>
					
                </li>
				
                <!--li class="nav-item dropdown open">
                    <a class="dropdown-toggle" href="javascript:void(0);">
                        <span class="icon-holder"><i class="c-orange-500 ti-layout-list-thumb"></i></span>
                        <span class="title">Server</span>
                        <span class="arrow"><i class="ti-angle-right"></i></span>
                    </a>
                    <ul class="dropdown-menu">
                        <li>
                            <a class='sidebar-link'>all Servers</a>
                        </li>
                        <li>
                            <a class='sidebar-link'>new Server</a>
                        </li>
                    </ul>
                </li-->
				
                <li class="nav-item" v-if="disabled">
                    <a class='sidebar-link' href="">
                        <span class="icon-holder"><i class="c-brown-500 ti-email"></i></span>
                        <span class="title">Email (Just admin)</span>
                    </a>
                </li>
                <li class="nav-item" v-if="disabled">
                    <a class='sidebar-link' href="">
                        <span class="icon-holder">
					<i class="c-blue-500 ti-share"></i>
				</span>
                        <span class="title">Compose</span>
                    </a>
                </li>
                <li class="nav-item" v-if="disabled">
                    <a class='sidebar-link' href="calendar.html">
                        <span class="icon-holder">
					<i class="c-deep-orange-500 ti-calendar"></i>
				</span>
                        <span class="title">Calendar</span>
                    </a>
                </li>
                <li class="nav-item" v-if="disabled">
                    <a class='sidebar-link' href="chat.html">
                        <span class="icon-holder">
					<i class="c-deep-purple-500 ti-comment-alt"></i>
				</span>
                        <span class="title">Chat</span>
                    </a>
                </li>
                <li class="nav-item" v-if="disabled">
                    <a class='sidebar-link' href="charts.html">
                        <span class="icon-holder">
					<i class="c-indigo-500 ti-bar-chart"></i>
				</span>
                        <span class="title">Charts</span>
                    </a>
                </li>
                <li class="nav-item" v-if="disabled">
                    <a class='sidebar-link' href="forms.html">
                        <span class="icon-holder">
					<i class="c-light-blue-500 ti-pencil"></i>
				</span>
                        <span class="title">Forms</span>
                    </a>
                </li>
                <li class="nav-item dropdown" v-if="disabled">
                    <a class="sidebar-link" href="ui.html">
                        <span class="icon-holder">
					<i class="c-pink-500 ti-palette"></i>
					</span>
                        <span class="title">UI Elements</span>
                    </a>
                </li>
                <li class="nav-item dropdown" v-if="disabled">
                    <a class="dropdown-toggle" href="javascript:void(0);">
                        <span class="icon-holder">
					<i class="c-orange-500 ti-layout-list-thumb"></i>
				</span>
                        <span class="title">Tables</span>
                        <span class="arrow">
					<i class="ti-angle-right"></i>
				</span>
                    </a>
                    <ul class="dropdown-menu">
                        <li>
                            <a class='sidebar-link' href="basic-table.html">Basic Table</a>
                        </li>
                        <li>
                            <a class='sidebar-link' href="datatable.html">Data Table</a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item dropdown" v-if="disabled">
                    <a class="dropdown-toggle" href="javascript:void(0);">
                        <span class="icon-holder">
					<i class="c-purple-500 ti-map"></i>
					</span>
                        <span class="title">Maps</span>
                        <span class="arrow">
					<i class="ti-angle-right"></i>
					</span>
                    </a>
                    <ul class="dropdown-menu">
                        <li>
                            <a href="google-maps.html">Google Map</a>
                        </li>
                        <li>
                            <a href="vector-maps.html">Vector Map</a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item dropdown" v-if="disabled">
                    <a class="dropdown-toggle" href="javascript:void(0);">
                        <span class="icon-holder">
					<i class="c-red-500 ti-files"></i>
					</span>
                        <span class="title">Pages</span>
                        <span class="arrow">
					<i class="ti-angle-right"></i>
					</span>
                    </a>
                    <ul class="dropdown-menu">
                        <li>
                            <a class='sidebar-link' href="blank.html">Blank</a>
                        </li>
                        <li>
                            <a class='sidebar-link' href="404.html">404</a>
                        </li>
                        <li>
                            <a class='sidebar-link' href="500.html">500</a>
                        </li>
                        <li>
                            <a class='sidebar-link' href="signin.html">Sign In</a>
                        </li>
                        <li>
                            <a class='sidebar-link' href="signup.html">Sign Up</a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item dropdown" v-if="disabled">
                    <a class="dropdown-toggle" href="javascript:void(0);">
                        <span class="icon-holder">
					<i class="c-teal-500 ti-view-list-alt"></i>
				</span>
                        <span class="title">Multiple Levels</span>
                        <span class="arrow">
					<i class="ti-angle-right"></i>
				</span>
                    </a>
                    <ul class="dropdown-menu">
                        <li class="nav-item dropdown">
                            <a href="javascript:void(0);">
                                <span>Menu Item</span>
                            </a>
                        </li>
                        <li class="nav-item dropdown">
                            <a href="javascript:void(0);">
                                <span>Menu Item</span>
                                <span class="arrow">
						<i class="ti-angle-right"></i>
					</span>
                            </a>
                            <ul class="dropdown-menu">
                                <li>
                                    <a href="javascript:void(0);">Menu Item</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Menu Item</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
	import { DropOptions, onBoard } from '@/components'
	export default {
        name: 'LeftSidebar',
		components: {
			DropOptions,
			onBoard
		},
		data() {
			return {
				menuItems	: [
					{ id : 0, name:'dashboard', title: 'Dashboard', icon:'c-blue-500 ti-stats-up', 	selected: false, children:[],  child:[]},
					{ id : 1, name:'', title: 'Lists Management', 	icon:'c-orange-500 ti-layout-list-thumb', selected: false, child:['sources', 'lists'], 
						children:[
							{ id : 0, name:'sources', title: 'New List', selected: false },
							{ id : 1, name:'lists',   title: 'My Lists', selected: false },
						]
					},
					{ id : 2, name:'', title: 'Leads', icon:'c-purple-500 fa fa-filter', selected: false, child:['leads', 'contacts'], /* ti-announcement */ /*  and Contacts */
						children:[
							{ id : 0, name:'leads', 		title: 'My Leads', 		selected: false },
							// { id : 1, name:'contacts', 		title: 'My Contacts', 	selected: false },
						]
					},
					{ id : 3, name:'', title: 'Campaigns', icon:'c-teal-500 fa fa-bullhorn', selected: false, child:['campaign.create', 'campaigns'], /* ti-announcement */
						children:[
							{ id : 0, name:'campaign.create', 	title: 'Create Campaign', 	selected: false },
							{ id : 1, name:'email.campaigns',	title: 'Email Campaigns', 	selected: false },
							{ id : 2, name:'fb.campaigns', 		title: 'Facebook Campaigns',selected: false },
						]
					},
					{ id : 4, name:'', title: 'Resources', icon:'c-red-500 ti-briefcase', selected: false, child:['domains', 'senders'], /* ti-announcement */
						children:[
							{ id : 0, name:'domains', 	title: 'Domains name',		selected: false },
							{ id : 1, name:'senders', 	title: 'Sender & Web Pages',	selected: false },
							{ id : 2, name:'fb.pages', 	title: 'Facebook Pages',	selected: false },
							{ id : 3, name:'myworks', 	title: 'Template Builder',	selected: false },
						]
					},
				],
				disabled	: false,
				
				IconTeams	: 'fa fa-users'
			}
		},
		methods: {
			ChangeTeam() { // element
				let that = this
				
				that.IconTeams = 'fa fa-cog fa-spin'
				
				let url  	= '/team/change'
				let method  = 'POST'
				let data 	= { team: that.$store.state.TeamSelected }
				let request = { url: url, method: method, data: data }
				
				that.$store.dispatch('requests', request).then(() => {
					
					that.IconTeams = 'fa fa-users'
					location.reload()
					
				}).catch(( {response} ) => {
					
					that.ErrorHandler(response)
					that.IconTeams = 'fa fa-users'
					location.reload()
					
				} )
				
				
			}
		},
		computed: {
			PageSelected() {
				let that 			= this
				that.menuItems.forEach( function(item) {
					if( item.children.length > 0 ) {
						if( item.child.includes(that.$store.state.selectedPage) ) {
							item.selected = true
						}
					} else {
						if( that.$store.state.selectedPage == item.name ) {
							item.selected = true
						}
					}
				} )
				return that.$store.state.selectedPage
			}
		},
		mounted() {
			let that = this
			that.$store.dispatch('CheckTeams')
			/* adminator/sidebar */
			
			/* // export default ( function () {
			// Sidebar links
			$('.sidebar .sidebar-menu li a').on('click', function () {
				const $this = $(this);
				if ($this.parent().hasClass('open')) {
					$this.parent().children('.dropdown-menu').slideUp(200, () => {  $this.parent().removeClass('open');});
				} else {
					$this.parent().parent().children('li.open').children('.dropdown-menu').slideUp(200);
					$this.parent().parent().children('li.open').children('a').removeClass('open');
					$this.parent().parent().children('li.open').removeClass('open');
					$this.parent().children('.dropdown-menu').slideDown(200, () => {  $this.parent().addClass('open');});
				}
			}); */
			/* // Sidebar Activity Class
			const sidebarLinks = $('.sidebar').find('.sidebar-link');
			sidebarLinks.each((index, el) => {
				$(el).removeClass('active');
			}).filter(function () {
				const href = $(this).attr('href');
				const pattern = href[0] === '/' ? href.substr(1) : href;
				return pattern === (window.location.pathname).substr(1);
			}).addClass('active');
			// ٍSidebar Toggle
			$('.sidebar-toggle').on('click', e => {
				$('.app').toggleClass('is-collapsed');
				e.preventDefault();
			}); */
			/**
			 * Wait untill sidebar fully toggled (animated in/out)
			 * then trigger window resize event in order to recalculate
			 * masonry layout widths and gutters.
			 */
			// $('#sidebar-toggle').click(e => {
			// e.preventDefault();
			// setTimeout(() => {
			// /* window.dispatchEvent(window.EVENT); */
			// }, 300);
			// });
			// }());
			
		}
    };
</script>

<style lang="scss" scope>
	.is-toggle {
		.sidebar-inner .sidebar-menu li.dropdown.active ul.dropdown-ul {
			display: none !important;
		}
		&:hover .sidebar-inner .sidebar-menu li.active>ul.dropdown-ul {
			display: block !important;
		}
	}
	
	.nav-item {
		.dropdown-ul {
			background-color: #171c22;
			border-radius: 0;
			border: 0;
			-webkit-box-shadow: none;
			box-shadow: none;
			float: none;
			padding-left: 50px;
			padding-top: 0;
			position: relative;
			width: 100%;
			
			overflow: hidden;
			max-height: 0px;
			z-index: 10;
			
			// transition: all ease-in-out .2s;
			// transform: scaleY(1);
			
			// transform: scaleY(0);
			// transform-origin: top center;
			// transition: all ease-out .8s;
			transition: all 0.8s;
			
			li a {
				display: block;
				padding: 10px 15px;
				color: #fff !important;
			}
		}
		a.dropdown-toggle {
			.arrow {
				transform: rotate(0deg);
				transition: all ease-out .4s;
			}
		}
		&.active {
			a.dropdown-toggle .arrow {
				transform: rotate(90deg);
				transition: all ease-oin .4s;
			}
			ul.dropdown-ul {
				transition: all ease-in-out 1s;
				transform: scaleY(1);
				max-height: 500px; /* auto */
			}
		}
	}
	
	.sidebar {
		
		.sidebar-inner {
			.dark-sidebar {
				background-color: #222d32;
				@media screen and (max-width: 640px) {
					li a:hover, li a:focus {
						background: #1e282c;
					}
				}
				>li {
					a {
						color: #fff !important;
						&:hover, &.active {
							background: #1e282c; /* #343f44 */
						}
					}
				}
			}
			.sidebar-menu{
				>li.dropdown {
					.dropdown-ul {
						>li {
							.sidebar-link {
								cursor: pointer;
								&:hover, &.active {
									background-color: #343f44;
								}
							}
						}
					}
				}
			}
		}
	}
</style>
