<template>
	<div class="components-page-header d-flex">
		<div class="logo-wrapper cursor-pointer" @click="$router.push('/dashboard')">
			<img src="@/assets/Builder/images/logo.png" alt="">
			<span>Templates Builder</span>
		</div>
		
		<div class="text-right my-auto ml-auto">
			<router-link :to="{name: 'dashboard'}" class="btn btn-link btn-sm">
				Back to dashboard <i class="ti-angle-right" style="font-size: 8px;"></i>
			</router-link>
		</div>
		
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: "page-header",
		components: {
			
		},
		data() {
			return {

			}
		},
		computed: {
			
		},
		methods: {
			goLogin(){
				// Save the url entered by the user
				this.$mUtils.Cookie.set('beforeLoginUrl',encodeURIComponent(this.$route.fullPath), 1/24/60, window.location.host, window.location.pathname.substring(0, window.location.pathname.length-1))
			}
		},
	}
</script>

<style lang="scss" scoped>
	.el-dropdown-link {
		color: white;
	}
	.user-header-image {
		display: inline-block;
		vertical-align: middle;
		width: 32px;
		height: 32px;
		border-radius: 50%;
		overflow: hidden;
		margin-right: 4px;
		font-size: 32px;
		line-height: 32px;
		text-align: center;
		margin-top: -5px;
	}
</style>

<style lang="scss">
	.components-page-header {
		padding-left: 12px;
		padding-right: 12px;
		background: rgba(255, 255, 255, 1);
		box-shadow: 0px 1px 4px 0px rgba(195, 195, 195, 0.6);
		border-bottom: 1px solid #e6ebed;
	}
	
	.logo-wrapper {
		height: 48px;
		line-height: 48px;
		font-size: 18px;
		font-family: Yuanti SC;
		color: rgba(0, 0, 0, 1);
		display: inline-block;
		width: 198px;
		border-right: 1px solid #e6ebed;
		img {
			width: 28px;
			height: 28px;
			margin-right: 8px;
		}
	}
</style>
