<template>
	<footer class="position-relative main-footer">
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="4210 4850 1464.3 300"><!-- 4209.3 4850 1465 300 -->
			<path data-name="Path 1901" d="M-78,67.7s180-205.4,964-48,501.3-84,501.3-84l20.4,10000H0Z" transform="translate(4287 5000.3)" class="cls-1-piso" fill="#171347">
			</path>
		</svg>
		<div class="footer-dark"><!--  footer-dark bg-dark -->
			<!-- Footer -->
			<div class="container">
				<div class="row">
					<div class="col-lg-4 mb-5 mb-lg-0">
						<!-- Theme's logo -->
						<router-link :to="{ name : 'home' }">
							<img alt="Sender Lead" src="@/assets/HomePage/img/Logo/Icon.png" id="footer-logo">
						</router-link>
						<!-- SenderLead' mission -->
						<p class="mt-4 text-sm opacity-8 pr-lg-4">SenderLead attempts to bring the best development experience to individuals and companies by offering the tools needed for having a quick and solid start in most projects.</p>
						<!-- Social -->
						<ul class="nav mt-4 mb-2">
							<li class="nav-item">
								<a class="nav-link ml-0" href="https://www.linkedin.com/company/senderlead" target="_blank"><i class="ti-linkedin"></i></a>
							</li>
							<!--li class="nav-item">
								<a class="nav-link" href="http://facebook.com/senderlead" target="_blank"><i class="ti-twitter"></i></a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="http://facebook.com/senderlead" target="_blank"><i class="ti-instagram"></i></a>
							</li-->
							<li class="nav-item">
								<a class="nav-link" href="http://facebook.com/senderlead" target="_blank"><i class="ti-facebook"></i></a>
							</li>
						</ul>
					</div>
					<div class="col-lg-2 col-4 col-sm-4 ml-lg-auto mb-lg-0"><!-- mb-5 -->
						<h6 class="heading mb-3">Account</h6>
						<ul class="list-unstyled">
							<li><router-link :to="{name: 'register'}">Profile</router-link></li>
							<li><router-link :to="{name: 'register'}">Settings</router-link></li>
							<li><router-link :to="{name: 'register'}">Register</router-link></li>
							<li><router-link :to="{name: 'register'}">Notifications</router-link></li>
						</ul>
					</div>
					<div class="col-lg-2 col-4 col-sm-4 mb-lg-0"><!-- mb-5 -->
						<h6 class="heading mb-3">About</h6>
						<ul class="list-unstyled">
							<li><router-link :to="{name: 'register'}">Services</router-link></li>
							<!--li><router-link :to="{name: 'register'}">Pricing</router-link></li-->
							<li><a href="/pricing">Pricing</a></li>
							<li><a href="/contactus">Contact us</a></li>
							<li><router-link :to="{name: 'register'}">Careers</router-link></li>
						</ul>
					</div>
					<div class="col-lg-2 col-4 col-sm-4 mb-lg-0"><!-- mb-5 -->
						<h6 class="heading mb-3">Company</h6>
						<ul class="list-unstyled">
							<li><router-link :to="{name: 'register'}">Community</router-link></li>
							<li><a href="/contactus">Help center</a></li>
							<li><a href="/contactus">Support</a></li>
						</ul>
					</div>
				</div>
				<hr class="divider divider-fade divider-dark mb-4 mt-2">
				<div class="row align-items-center justify-content-md-between pb-4">
					<div class="col-md-6">
						<div class="copyright text-sm font-weight-bold text-center text-md-left">
							&copy; {{ thisYear }} <router-link :to="{name: 'register'}" class="font-weight-bold">SenderLead</router-link>. All rights reserved
						</div>
					</div>
					<div class="col-md-6">
						<ul class="nav justify-content-center justify-content-md-end mt-3 mt-md-0">
							<li class="nav-item">
								<a class="nav-link" href="/terms-of-service">Terms of Service</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="/privacy-policy">Privacy Policy</a>
							</li>
							<!--li class="nav-item">
								<router-link :to="{name: 'register'}" class="nav-link">Cookies</router-link>
							</li-->
						</ul>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>
<script>
	export default {
		name: 'FooterContent',
		data() {
			return {
				thisYear: new Date().getFullYear(),
			};
		}
	};
</script>
<style lang="scss">
	.main-footer {
		box-shadow: none;
		.footer-dark {
			padding-top: 0rem;
			background-color: #171347;
			margin-top: -1px;
			p {
				color: rgba(255,255,255,.8);
			}
			.nav-link {
				margin: 0.5rem 1rem;
				padding: 0px;
			}
			.list-unstyled li a, .nav .nav-item .nav-link {
				color: #a0aec0;
				&:hover {
					color: #fff;
				}
			}
			.copyright {
				color: #a0aec0;
				a {
					color: #fff;
				}
			}
			.heading {
				color: #fff;
			}
			
			hr {
				border-color: rgba(216,203,187,.1);
			}
			
			#footer-logo {
				position: absolute;
				top: -60px;
				width: 95px;
			}
			
		}
	}
</style>
